import * as React from "react";
import { cn, DataComponent, DataObserveKey, VisualComponent } from "@siteimprove/fancylib";
import * as scss from "./thumbnail.scss";

type ThumbnailProps = {
	/** Src for the thumbnail image */
	src: string;
	/** Alt text for the image */
	alt: string;
	/** Should thumbnail do a zoom effect on hover */
	zoom?: boolean;
	/** Size of the thumbnail, defaults to `"small"` */
	size?: "small" | "medium" | "fullWidth";
	/** Id applied to the thumbnail */
	id?: string;
	/** Indicates whether the element is exposed to an accessibility API. */
	"aria-hidden"?: boolean;
} & DataObserveKey &
	VisualComponent;

export function Thumbnail(props: ThumbnailProps): JSX.Element {
	const { src, alt, zoom, size = "small", id, "aria-hidden": ariaHidden, className, style } = props;

	const attributes: React.HTMLAttributes<HTMLElement> & DataComponent & DataObserveKey = {
		"data-component": "thumbnail",
		"data-observe-key": props["data-observe-key"],
		"aria-hidden": ariaHidden,
		id: id,
		className: cn(className, scss.imgContainer, scss[size]),
		style: style,
	};

	return (
		<div {...attributes}>
			<img src={src} alt={alt} className={cn(zoom && scss.zoom)} />
		</div>
	);
}
