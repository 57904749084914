import * as React from "react";
import { useTranslations } from "../../../context/fancy-context";
import {
	DataObserveKey,
	VisualComponent,
	FocusableComponent,
} from "../../../types/fancy-base-attributes";
import { cn, f2u } from "../../../utils/shorthands";
import { SrOnly } from "../../text/sr-only/sr-only";
import * as scss from "./link.scss";

export interface LinkType {
	/** Url of the page the link redirects to */
	href: string;
	/** Opens the link in a new tab if set to true */
	openNew?: boolean;
}

export type LinkProps = LinkType & {
	/** Label for the link */
	children: React.ReactNode;
	/** Callback for onClick events */
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
	/** Renders the link inline if set to true, should be used when the link is part of a text block */
	inlineInText?: boolean;
	/** Renders the link without style if set to true */
	unstyled?: boolean;
	/** Size of the link, defaults to `"medium"` */
	size?: "small" | "medium" | "large";
	/** Screen reader text for the link */
	"aria-label"?: string;
	/** When link is wrapped into single words */
	disableWordBreak?: boolean;
} & DataObserveKey &
	VisualComponent &
	FocusableComponent;

export function Link(props: LinkProps): JSX.Element {
	const {
		href,
		openNew,
		onClick,
		inlineInText,
		children,
		unstyled,
		size = "medium",
		className,
		style,
		onBlur,
		onFocus,
		onKeyDown,
		onMouseDown,
		tabIndex,
		onMouseEnter,
		onMouseLeave,
		disableWordBreak,
		...rest
	} = props;

	const i18n = useTranslations();

	return (
		<a
			data-observe-key={props["data-observe-key"]}
			data-component="link"
			href={href}
			aria-label={props["aria-label"]}
			className={cn(
				className,
				scss.link,
				inlineInText && scss.inlineLink,
				unstyled && scss.unstyled,
				disableWordBreak && scss.disableWordBreak,
				scss[size]
			)}
			style={style}
			target={f2u(openNew && "_blank")}
			rel={f2u(openNew && "noopener")}
			onBlur={onBlur}
			onFocus={onFocus}
			onKeyDown={onKeyDown}
			onMouseDown={onMouseDown}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onClick={onClick}
			tabIndex={tabIndex}
			{...rest}
		>
			{openNew && <SrOnly isInline={inlineInText}>{`${i18n.linkButtonOpenNewAriaLabel}:`}</SrOnly>}
			{children}
			{!inlineInText && !unstyled && (
				<span className={cn(scss.linkIndicator, openNew && scss.openNew)} aria-hidden="true">
					&nbsp;{openNew ? <>&#8599;</> : <>&rarr;</>}
				</span>
			)}
		</a>
	);
}
