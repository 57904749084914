import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { TextContainer, Ul, Link, Paragraph } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import image from "../../../../../src/docs/example-img.png";
import { Thumbnail } from "./thumbnail";

export const Meta: DocPageMeta = {
	category: "Visuals",
	title: "Thumbnail",
	notepad: "https://hackmd.io/93zBsPbuSg2Q23p1Y16cow",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Thumbnail"
			subTitle="Thumbnails are compact visual representations of larger images or content. They provide users with a quick preview and help them navigate or select relevant items."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={Thumbnail} />
				<Header.H2>Purpose</Header.H2>
				<Paragraph>
					Thumbnails provide users with a quick visual overview of larger content (e.g Snapshot,
					image). They help users:
				</Paragraph>
				<Ul
					items={[
						"Preview content: Quickly assess if the content is relevant to their needs.",
						"Navigate: Choose from multiple options without having to open each one.",
						"Scan: Efficiently browse through a collection of items.",
					]}
				/>
				<Header.H2>Examples</Header.H2>
				<Header.H4>Composition:</Header.H4>
				<Ul
					items={[
						<>
							<b>Image</b>: The primary visual element of the thumbnail.
						</>,
						<>
							<b>Container</b>: A surrounding element that defines the thumbnail's boundaries,
							aspect ratio, and provides optional styling (e.g., borders, shadows).
						</>,
					]}
				/>
				<Header.H3>Default</Header.H3>
				<Example fn={defaultExample} />
				<Header.H3>Size</Header.H3>
				<Ul
					items={[
						<>
							<b>Default</b> (Small): Ideal for displaying multiple items in a compact space (e.g.,
							image galleries, card layouts).
						</>,
						<>
							<b>Medium</b>: Offers a larger preview, providing more detail for items where visuals
							are important.
						</>,
						<>
							<b>Full-Width</b>: Maximizes visual impact and is often used for featured content or
							in hero sections.
						</>,
					]}
				/>
				<Example fn={sizeExample} />
				<Header.H3>Link</Header.H3>
				<Paragraph>
					A link thumbnail is interactive and directs users to the associated content when clicked.
				</Paragraph>
				<Example fn={linkExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={Thumbnail}
					initialProps={{
						src: image,
						alt: "Screenshot of Siteimprove website in Danish 2020/09/25",
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>Thumbnail</Code> when
					</Paragraph>
					<Ul
						items={[
							"You have a collection of visual content (e.g images) that users need to browse or select from.",
							"Space is limited and you need to present multiple options efficiently.",
							"To give users a quick glimpse of the content before they commit to viewing the full version.",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>Thumbnail</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<GatsbyLink to="/lab/components/Tables%20and%20lists/Table">Table</GatsbyLink> /{" "}
								<GatsbyLink to="/lab/components/Tables%20and%20lists/List%20table">
									List table
								</GatsbyLink>{" "}
								: thumbnails provide a visual anchor, making it easier for users to quickly scan and
								identify specific items.
							</>,
							<>
								Site picker: thumbnails offer a quick way to visually differentiate between sites.
								Users can recognize their target site based on a representative screenshot.
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>Thumbnail</Code> to existing components for visual
								consistency.
							</>,
							<>
								Use high-quality images that are clear and representative of the content. Ensure
								images are properly cropped and optimized for fast loading.
							</>,
							<>
								Maintain a consistent aspect ratio across all thumbnails for visual harmony. The
								most common aspect ratios are 4:3, and 16:9.
							</>,
							<>
								If an image is unavailable, display a placeholder that maintains the thumbnail's
								aspect ratio and provides a visual indication that content is loading.
							</>,

							<>
								Thumbnails work well in grid layouts and lists. Ensure consistent spacing for visual
								clarity.
							</>,
							<>
								Consider adding captions below thumbnails to provide additional context or
								information, especially for the full-Width size thumbnail.
							</>,
						]}
					/>
					<Header.H4>Interaction</Header.H4>
					<Ul
						items={[
							<>
								Provide visual feedback when users hover over or interact with a thumbnail (e.g.
								<GatsbyLink to="/lab/components/Visuals/Thumbnail#link">
									Thumbnail with link
								</GatsbyLink>{" "}
								){" "}
							</>,
							<>
								If necessary, use{" "}
								<GatsbyLink to="/lib/components/Feedback/spinner">Spinner</GatsbyLink> to provide
								feedback while thumbnails are loading, especially on slower connections.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"The content is primarily text-based or lacks a visual representation, a thumbnail may not be useful.",
							"If the image is too detailed or busy to be understood at a small size, consider a different preview method.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							"Ensure sufficient contrast between the thumbnail image and its background.",
							"Provide alternative text for screen readers.",
							"Use clear and descriptive link text or aria-labels.",
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Use clear, concise, and descriptive language for alt text.",
							"Avoid jargon and technical terms.",
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

/** @ignore due to image reference */
const defaultExample = () => (
	<Thumbnail src={image} alt="Screenshot of Siteimprove website in Danish 2020/09/25" />
);

/** @ignore due to image reference */
const sizeExample = () => (
	<>
		<Thumbnail
			style={{ display: "block" }}
			src={image}
			alt="Screenshot of Siteimprove website in Danish 2020/09/25"
			size="small"
		/>
		<Thumbnail
			style={{ display: "block" }}
			src={image}
			alt="Screenshot of Siteimprove website in Danish 2020/09/25"
			size="medium"
		/>
		<Thumbnail
			style={{ display: "block" }}
			src={image}
			alt="Screenshot of Siteimprove website in Danish 2020/09/25"
			size="fullWidth"
		/>
	</>
);

/** @ignore due to image reference */
const linkExample = () => (
	<Link href="https://www.google.com" unstyled openNew>
		<Thumbnail zoom src={image} alt="Screenshot of Siteimprove website in Danish 2020/09/25" />
	</Link>
);
